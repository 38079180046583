import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Button = ({ children, login, href, wfull, dark, event }) => {
  const { t } = useTranslation();
  const { lang } = useParams();

  if (event) {
    return (
      <div
        className={`${classes.button} ${wfull ? classes.wfull : ''} ${dark ? classes.dark : ''} no-select`}
        onClick={event}
      >
        <span>
          {children
            ? children
            : t('btn.registration')
          }
        </span>
      </div>
    );
  }

  return (
    <Link
      className={`${classes.button} ${wfull ? classes.wfull : ''} ${dark ? classes.dark : ''} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${href}`
      }
    >
      <span>
        {children
          ? children
          : t('btn.registration')
        }
      </span>
    </Link>
  );
};

export default Button;
