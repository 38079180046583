import React from "react";
import classes from './Accounts.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import check from '../../../assets/img/HomePage/check.svg';
import image from '../../../assets/img/HomePage/accounts_banner.svg';

const cards = [
    {
        subtitle: 'home.accounts.card_01.subtitle',
        desc: 'home.accounts.card_01.desc',
        price: '$500',
        rows: [
            '$500',
            '1:20',
            '1:20',
            '1:20',
            '1:50',
            true,
            false,
            false,
            false,
            false,
        ],
    },
    {
        subtitle: 'home.accounts.card_02.subtitle',
        desc: 'home.accounts.card_02.desc',
        price: '$25 000',
        rows: [
            '$25 000',
            '1:20',
            '1:20',
            '1:20',
            '1:50',
            true,
            true,
            true,
            false,
            false,
        ],
    },
    {
        subtitle: 'home.accounts.card_03.subtitle',
        desc: 'home.accounts.card_03.desc',
        price: '$50 000',
        rows: [
            '$50 000',
            '1:20',
            '1:20',
            '1:20',
            '1:50',
            true,
            true,
            true,
            true,
            true,
        ],
    },
];

const rows = [
    'home.accounts.row_01',
    'home.accounts.row_02',
    'home.accounts.row_03',
    'home.accounts.row_04',
    'home.accounts.row_05',
    'home.accounts.row_06',
    'home.accounts.row_07',
    'home.accounts.row_08',
    'home.accounts.row_09',
    'home.accounts.row_10',
];

const Accounts = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.wrap} mt`}>
            <div className="container">
                <div className={classes.body}>
                    <div className={classes.head}>
                        <h2 className={`${classes.title} font-60`}>
                            {t('home.accounts.title')}
                        </h2>
                        <div className={`${classes.text} txt-container font-20`}>
                            <p>
                                {t('home.accounts.text_01')}
                            </p>
                            <p>
                                {t('home.accounts.text_02')}
                            </p>
                        </div>
                    </div>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <div className={classes.headingWrap}>
                                    <div className={classes.heading}>
                                        <div className={classes.info}>
                                            <div className={classes.num}>
                                                0{index + 1}
                                            </div>
                                            <p className={`${classes.subtitle} font-20`}>
                                                {t(card.subtitle)}
                                            </p>
                                        </div>
                                        <p className={`${classes.price} font-40`}>
                                            {card.price}
                                        </p>
                                    </div>
                                    <p className={`${classes.desc} font-18`}>
                                        {t(card.desc)}
                                    </p>
                                </div>
                                <div className={classes.content}>
                                    <div className={`${classes.rows} font-18`}>
                                        {card.rows.map((row, rowIndex) =>
                                            <div 
                                                key={rowIndex} 
                                                className={`${classes.row} ${row === false ? classes.hidden : ''}`}
                                            >
                                                <img className={classes.check} src={check} alt='' />
                                                <div className={classes.rowText}>
                                                    {t(rows[rowIndex])}
                                                    {typeof (row) === 'string' &&
                                                        <span> {row}</span>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Button wfull />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes.banner}>
                    <h2 className={`${classes.title} font-48`}>
                        {t('home.accounts.banner.title')}
                    </h2>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Accounts;
