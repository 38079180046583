import _ from 'lodash';
import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import i18n from "i18next";
import $ from "jquery";
import logo from "../../../assets/img/logo.svg";
import Button from '../Button/Button';

const languages = [
  { value: 'en', label: 'EN', flag: 'https://flagcdn.com/w20/gb.png' },
  { value: 'tr', label: 'TR', flag: 'https://flagcdn.com/w20/tr.png' },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const getRoute = (route) => {
    if (lang && route) {
      return `/${lang}${route}`;
    }
    return `/${i18n.language}${route}`;
  }

  const setLocale = (newLang) => {
    let newPathname = pathname;
    i18n.changeLanguage(newLang);

    if (lang && newLang) {
      newPathname = pathname.replace(`/${lang}`, `/${newLang}`);
    }

    navigate(newPathname);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  }

  useEffect(() => {
    listenScroll();
    burgerOpened
      ? $("body").addClass("lock")
      : $("body").removeClass("lock");
  }, [burgerOpened]);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
    setBurgerOpened(false);
  }, [pathname]);

  useEffect(() => {
    $("body").hasClass("lock")
      && $("body").removeClass("lock");
  }, []);

  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  const customOption = ({ value, label, flag }) => (
    <div className={classes.optionStyle}>
      <img src={flag} alt={label} style={{ width: '20px', marginRight: '10px' }} />
      {label}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      '&:hover': { border: 'none' },
      padding: '0',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      fontSize: '16px',
      minHeight: 'unset',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '7px 10px',
      backgroundColor: state.isSelected ? '#ffffff30' : '#ffffff0',
      cursor: 'pointer',
      '&:hover': { backgroundColor: '#ffffff20' },
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: '#000'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#050501',
      boxShadow: '0 2px 5px #ffffff40',
    }),
  };

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link
              to={getRoute('/')}
              onClick={() => setBurgerOpened(false)}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {/* {headerRoutes.map((route, routeIndex) => (
              route.path ?
                <Link
                  key={routeIndex}
                  to={getRoute(route.path)}
                  className={classes.linkItem}
                >
                  {t(route.text)}
                </Link>
                :
                <div key={routeIndex} className={classes.listWrap}>
                  <div className={classes.linkItem}>
                    {t(route.text)}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div className={classes.subRoutes}>
                    {route.routes.map((subRoute, subRouteIndex) =>
                      <Link
                        key={subRouteIndex}
                        className={`${classes.subRoute} ${classes.linkItem}`}
                        to={getRoute(subRoute.path)}
                      >
                        {t(subRoute.text)}
                      </Link>
                    )}
                  </div>
                </div>
            ))} */}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Select
                options={languages}
                placeholder={_.toUpper(lang)}
                onChange={(e) => setLocale(e.value)}
                value={languages.find(flang => flang.value === lang)}
                getOptionLabel={customOption}
                getOptionValue={(option) => option.value}
                styles={customStyles}
              />
            </div>
            <a
              href={`${process.env.REACT_APP_CABINET_URL}/login?lang=${lang}`}
              className={`${classes.login} font-18`}
            >
              {t('btn.login')}
            </a>
            <div className={classes.signup}>
              <Button />
            </div>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened && classes.mobileMenuActive}`}>
        <div className={`container ${classes.mobileMenuContainer}`}>
          <a
            href={`${process.env.REACT_APP_CABINET_URL}/login?lang=${lang}`}
            className={`${classes.login} font-18`}
          >
            {t('btn.login')}
          </a>
          <div className={classes.signup}>
            <Button />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;