import React from "react";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import Underbanner from "../components/HomePage/Underbanner/Underbanner";
import Features from "../components/HomePage/Features/Features";
import AboutUs from "../components/HomePage/AboutUs/AboutUs";
import CardsMarquee from "../components/HomePage/CardsMarquee/CardsMarquee";
import AboutCompany from "../components/HomePage/AboutCompany/AboutCompany";
import Markets from "../components/HomePage/Markets/Markets";
import Regulated from "../components/HomePage/Regulated/Regulated";
import Process from "../components/HomePage/Process/Process";
import Accounts from "../components/HomePage/Accounts/Accounts";
import Faq from "../components/HomePage/Faq/Faq";
import Contacts from "../components/HomePage/Contacts/Contacts";
import Questions from "../components/HomePage/Questions/Questions";
import SharesMarquee from "../components/HomePage/SharesMarquee/SharesMarquee";
import Partnership from "../components/HomePage/Partnership/Partnership";

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <Underbanner />
      <Features />
      <AboutUs />
      <CardsMarquee />
      <AboutCompany />
      <Markets />
      <Regulated />
      <SharesMarquee />
      <Process />
      <Accounts />
      <Partnership />
      <Faq />
      <Contacts />
      <Questions />
      <Footer />
    </div>
  );
};

export default HomePage;
