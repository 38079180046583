import React from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Underbanner/image_01.svg';
import image_02 from '../../../assets/img/HomePage/Underbanner/image_02.svg';
import image_03 from '../../../assets/img/HomePage/Underbanner/image_03.svg';
import image_04 from '../../../assets/img/HomePage/Underbanner/image_04.svg';

const images = [
    image_01,
    image_02,
    image_03,
    image_04,
];

const Underbanner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                {images.map((image, index) =>
                    <img key={index} className={classes.image} src={image} alt=''/>
                )}
            </div>
        </section>
    );
};

export default Underbanner;
