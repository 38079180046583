import React from "react";
import classes from './Regulated.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        subtitle: 'home.regulated.card_01.subtitle',
        desc: 'home.regulated.card_01.desc',
        href: '/docs/REFUND_POLICY.pdf',
    },
    {
        subtitle: 'home.regulated.card_02.subtitle',
        desc: 'home.regulated.card_02.desc',
        href: '/docs/USER_AGREEMENT.pdf',
    },
];

const Regulated = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-60`}>
                        {t('home.regulated.title')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        {t('home.regulated.text')}
                    </p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <div className={classes.content}>
                                <h4 className={`${classes.subtitle} font-40`}>
                                    {t(card.subtitle)}
                                </h4>
                                <p className={`${classes.desc} font-18`}>
                                    {t(card.desc)}
                                </p>
                            </div>
                            <a href={card.href} className={classes.button}>
                                <svg className={classes.arrow} width="41" height="24" viewBox="0 0 41 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5L2 10.5ZM40.0607 13.0607C40.6464 12.4749 40.6464 11.5251 40.0607 10.9393L30.5147 1.3934C29.9289 0.807611 28.9792 0.807611 28.3934 1.3934C27.8076 1.97919 27.8076 2.92893 28.3934 3.51472L36.8787 12L28.3934 20.4853C27.8076 21.0711 27.8076 22.0208 28.3934 22.6066C28.9792 23.1924 29.9289 23.1924 30.5147 22.6066L40.0607 13.0607ZM2 13.5L39 13.5V10.5L2 10.5L2 13.5Z" fill="#030301" />
                                </svg>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Regulated;
