import React from "react";
import classes from './AboutUs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Heading from "../../GlobalComponents/Heading/Heading";
import image from '../../../assets/img/HomePage/about.webp';

const cards = [
    {
        title: '25M+',
        text: 'home.about_us.li_01.text',
    },
    {
        title: '37K+',
        text: 'home.about_us.li_02.text',
    },
    {
        title: '10+',
        text: 'home.about_us.li_03.text',
    },
];

const AboutUs = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Heading center>
                    {t('home.about_us.heading')}
                </Heading>
                <h2 className={`${classes.title} font-60`}>
                    <Trans>
                        {t('home.about_us.title')}
                    </Trans>
                </h2>
                <div className={classes.body}>
                    <div className={classes.content}>
                        <div className={`${classes.text} font-20`}>
                            <p>
                                {t('home.about_us.text_01')}
                            </p>
                            <p>
                                {t('home.about_us.text_02')}
                            </p>
                            <p>
                                {t('home.about_us.text_03')}
                            </p>
                        </div>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <h4 className={`${classes.subtitle} font-48`}>
                                        {card.title}
                                    </h4>
                                    <p className={`${classes.desc} font-20`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img src={image} alt='' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
