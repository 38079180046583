import React from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Heading from "../../GlobalComponents/Heading/Heading";
import image from '../../../assets/img/HomePage/banner.webp';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Heading>
                        {t('home.banner.heading')}
                    </Heading>
                    <h1 className={`${classes.title} font-100`}>
                        <Trans>
                            {t('home.banner.title')}
                        </Trans>
                    </h1>
                    <p className={`${classes.text} font-20`}>
                        {t('home.banner.text')}
                    </p>
                    <Button />
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;
