import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo.svg'

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={`${classes.footerWrap} mt`}>
      <div className="container">
        <div className={classes.footer}>
          <div className={classes.head}>
            <img className={classes.logo} src={logo} alt='' />
            <div className={`${classes.rows} font-16`}>
              <a className={classes.link} href="tel:+441224076107">
                +441224076107
              </a>
              <a className={classes.link} href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
            </div>
            <div className={`${classes.rows} font-16`}>
              <a className={classes.link} href="/docs/REFUND_POLICY.pdf" target="_blank">
                {t('footer.docs.refund_policy')}
              </a>
              <a className={classes.link} href="/docs/USER_AGREEMENT.pdf" target="_blank">
                {t('footer.docs.user_agreement')}
              </a>
            </div>
          </div>
          <div className={`${classes.address} font-16`}>
            Office 1320 Blue Tower, Media City Uk, Manchester, United Kingdom, M50 2ST
          </div>
          <p className={`${classes.disclaimer} font-16`}>
            {t('footer.disclaimer')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
