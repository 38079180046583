import React from "react";
import classes from './Contacts.module.scss';
import { useTranslation } from "react-i18next";
import Heading from "../../GlobalComponents/Heading/Heading";

const Contacts = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Heading center>
                    {t('home.contacts.heading')}
                </Heading>
                <h2 className={`${classes.title} font-60`}>
                    {t('home.contacts.title')}
                </h2>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <div className={classes.content}>
                            <div className={`${classes.num} font-60`}>
                                1
                            </div>
                            <h4 className={`${classes.subtitle} font-24`}>
                                {t('home.contacts.card_01.subtitle')}
                            </h4>
                            <p className={`${classes.text} font-18`}>
                                {t('home.contacts.card_01.text')}
                            </p>
                        </div>
                        <a
                            href={`mailto:support@${hostname}`}
                            className={`${classes.info} font-24`}
                        >
                            support@{hostname}
                        </a>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.content}>
                            <div className={`${classes.num} font-60`}>
                                2
                            </div>
                            <h4 className={`${classes.subtitle} font-24`}>
                                {t('home.contacts.card_02.subtitle')}
                            </h4>
                            <p className={`${classes.text} font-18`}>
                                {t('home.contacts.card_02.text')}
                            </p>
                        </div>
                        <a
                            href="https://maps.app.goo.gl/iLJ12rb9R5WmrFc28"
                            target="_blank"
                            className={`${classes.info} font-24`}
                        >
                            Office 1320 Blue Tower, Manchester
                        </a>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.content}>
                            <div className={`${classes.num} font-60`}>
                                3
                            </div>
                            <h4 className={`${classes.subtitle} font-24`}>
                                {t('home.contacts.card_03.subtitle')}
                            </h4>
                            <p className={`${classes.text} font-18`}>
                                {t('home.contacts.card_03.text')}
                            </p>
                        </div>
                        <a
                            href="tel:+441224076107"
                            className={`${classes.info} font-24`}
                        >
                            +441224076107
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contacts;
