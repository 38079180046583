import React from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        subtitle: 'home.faq.card_01.subtitle',
        desc: 'home.faq.card_01.desc',
    },
    {
        subtitle: 'home.faq.card_02.subtitle',
        desc: 'home.faq.card_02.desc',
    },
    {
        subtitle: 'home.faq.card_03.subtitle',
        desc: 'home.faq.card_03.desc',
    },
    {
        subtitle: 'home.faq.card_04.subtitle',
        desc: 'home.faq.card_04.desc',
    },
    {
        subtitle: 'home.faq.card_05.subtitle',
        desc: 'home.faq.card_05.desc',
    },
];

const Faq = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-60`}>
                        {t('home.faq.title')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        {t('home.faq.text')}
                    </p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className={`${classes.subtitle} font-24`}>
                                {t(card.subtitle)}
                            </h4>
                            <p className={`${classes.desc} font-18`}>
                                {t(card.desc)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
