import React from "react";
import classes from './Partnership.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Partnership/image_01.png';
import image_02 from '../../../assets/img/HomePage/Partnership/image_02.png';
import image_03 from '../../../assets/img/HomePage/Partnership/image_03.png';
import image_04 from '../../../assets/img/HomePage/Partnership/image_04.png';

const images = [
    image_01,
    image_02,
    image_03,
    image_04,
];

const Partnership = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-60`}>
                    {t('home.partnership.title')}
                </h2>
                <div className={classes.cards}>
                    {images.map((image, index) =>
                        <img key={index} className={classes.image} src={image} alt='' />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Partnership;
