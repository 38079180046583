import React from "react";
import classes from './Heading.module.scss';

const Heading = ({ children, center }) => {
    return (
        <div className={`${classes.heading} ${center ? classes.center : ''}`}>
            <div className={classes.body}>
                <span>
                    {children}
                </span>
            </div>
        </div>
    );
};

export default Heading;
