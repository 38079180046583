export const validateString = (getter, setter) => {
  if (getter === '') {
    setter(false);
    return false;
  }
  setter(true);
  return true;
};

export const validatePhone = (phone, setPhoneValid) => {
  if (phone.length <= 10) {
    setPhoneValid(false);
    return false;
  }
  setPhoneValid(true);
  return true;
};

export const validateAgree = (agree, setAgreeValid) => {
  if (agree) {
    setAgreeValid(true)
    return true;
  }
  setAgreeValid(false);
  return false;
};

export const validateEmail = (email, setEmailValid) => {
  const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!expression.test(String(email.toLocaleLowerCase()))) {
    setEmailValid(false);
    return false;
  }
  setEmailValid(true);
  return true;
};