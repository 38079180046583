import React from "react";
import classes from './SharesMarquee.module.scss';
import Marquee from "react-fast-marquee";
import icon_01 from '../../../assets/img/HomePage/SharesMarquee/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/SharesMarquee/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/SharesMarquee/icon_03.png';
import icon_04 from '../../../assets/img/HomePage/SharesMarquee/icon_04.svg';
import icon_05 from '../../../assets/img/HomePage/SharesMarquee/icon_05.svg';
import icon_06 from '../../../assets/img/HomePage/SharesMarquee/icon_06.svg';
import icon_07 from '../../../assets/img/HomePage/SharesMarquee/icon_07.svg';
import icon_08 from '../../../assets/img/HomePage/SharesMarquee/icon_08.png';
import icon_09 from '../../../assets/img/HomePage/SharesMarquee/icon_09.png';
import icon_10 from '../../../assets/img/HomePage/SharesMarquee/icon_10.svg';
import star from '../../../assets/img/HomePage/star.svg';

const icons = [
    icon_01,
    icon_02,
    icon_03,
    icon_04,
    icon_05,
    icon_06,
    icon_07,
    icon_08,
    icon_09,
    icon_10,
];

const SharesMarquee = () => {
    return (
        <section className={`${classes.wrap} mt`}>
            <Marquee autoFill speed={160}>
                {icons.map((icon, index) =>
                    <div key={index} className={classes.item}>
                        <img className={classes.icon} src={icon} alt='' />
                        <img className={classes.star} src={star} alt='' />
                    </div>
                )}
            </Marquee>
        </section>
    );
};

export default SharesMarquee;
