import React, { useEffect, useRef, useState } from 'react';
import classes from './EconomicCalendar.module.scss';

const TradingViewWidget = () => {
    const widgetRef = useRef(null);
    const [domLoaded, setDomLoaded] = useState(false);

    useEffect(() => {
        if (domLoaded) {
            setTimeout(() => {
                const script = document.createElement('script');
                script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
                script.async = true;

                // Передаем конфигурацию виджета в script
                script.innerHTML = JSON.stringify({
                    colorTheme: "dark",
                    isTransparent: true,
                    width: "100%",
                    height: "100%",
                    locale: "en",
                    importanceFilter: "-1,0,1",
                });

                // Убедитесь, что ссылка на DOM элемент существует перед вставкой скрипта
                if (widgetRef.current) {
                    widgetRef.current.appendChild(script);
                } else {
                    console.error('widgetRef is null, script cannot be appended');
                }

                // Очистка после размонтирования
                return () => {
                    if (widgetRef.current) {
                        widgetRef.current.innerHTML = ''; // Очищаем контейнер
                    }
                };
            }, 1000);
        }
    }, [domLoaded]);

    useEffect(() => {
        setDomLoaded(true);
    }, []);

    return (
        <div ref={widgetRef} className={classes.widget}></div>
    );
};

export default TradingViewWidget;
