import React from "react";
import classes from './CardsMarquee.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import icon_01 from '../../../assets/img/HomePage/CardsMarquee/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/CardsMarquee/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/CardsMarquee/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/CardsMarquee/icon_04.svg';
import icon_05 from '../../../assets/img/HomePage/CardsMarquee/icon_05.svg';
import icon_06 from '../../../assets/img/HomePage/CardsMarquee/icon_06.svg';

const cards = [
    {
        icon: icon_01,
        title: 'Apple',
        ticker: 'APP.US',
        percent: '+32%',
        background: 'linear-gradient(285deg, #DBDBDB 0%, #99999B 100%)',
    },
    {
        icon: icon_02,
        title: 'McDonalds',
        ticker: 'MCD',
        percent: '+43%',
        background: '#ED3E44',
    },
    {
        icon: icon_03,
        title: 'AMD',
        ticker: 'AMD.US',
        percent: '+58%',
        background: 'linear-gradient(285deg, #07C484 0%, #009A66 100%)',
    },
    {
        icon: icon_04,
        title: 'Alphabet Inc',
        ticker: 'Alphabet Inc.US',
        percent: '+67%',
        background: 'linear-gradient(285deg, #ED676B 0%, #FF810D 100%)',
    },
    {
        icon: icon_05,
        title: 'Tesla',
        ticker: 'TSLA.US',
        percent: '+48%',
        background: 'linear-gradient(285deg, #595858 0%, #1A1919 100%)',
    },
    {
        icon: icon_06,
        title: 'Mastercard Inc',
        ticker: 'MA',
        percent: '+43%',
        background: 'linear-gradient(285deg, #F74D52 0%, #E53238 100%)',
    },
];

const CardsMarquee = () => {
    const { t } = useTranslation();
    return (
        <section className="mt">
            <div className={classes.wrap}>
                <Marquee autoFill speed={120}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div
                                key={index}
                                className={classes.card}
                                style={{ background: card.background }}
                            >
                                <div className={classes.head}>
                                    <div className={classes.info}>
                                        <p className={`${classes.name} font-28`}>
                                            {card.title}
                                        </p>
                                        <p className={`${classes.ticker} font-20`}>
                                            {card.ticker}
                                        </p>
                                    </div>
                                    <img className={classes.icon} src={card.icon} alt='' />
                                </div>
                                <div className={classes.bottom}>
                                    <p className={classes.year}>
                                        {t('home.last_year')}
                                    </p>
                                    <p className={`${classes.percent} font-28`}>
                                        {card.percent}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </Marquee>
            </div>
        </section>
    );
};

export default CardsMarquee;
