import React from "react";
import classes from './AboutCompany.module.scss';
import { Trans, useTranslation } from "react-i18next";
import check from '../../../assets/img/HomePage/check.svg';
import star from '../../../assets/img/HomePage/star.svg';
import Marquee from "react-fast-marquee";

const AboutCompany = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-60`}>
                        <Trans>
                            {t('home.about_company.title')}
                        </Trans>
                    </h2>
                    <div className={`${classes.text} font-20 txt-container`}>
                        <p>
                            {t('home.about_company.text_01')}
                        </p>
                        <p>
                            {t('home.about_company.text_02')}
                        </p>
                        <p>
                            {t('home.about_company.text_03')}
                        </p>
                    </div>
                </div>
                <div className={classes.cards}>
                    <div className={`${classes.card} ${classes.card_01}`}>
                        <div className={classes.content}>
                            <img className={classes.check} src={check} alt='' />
                            <p className={`${classes.desc} font-18`}>
                                {t('home.about_company.card_01.text')}
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.card} ${classes.card_02}`}>
                        <div className={classes.content}>
                            <img className={classes.check} src={check} alt='' />
                            <p className={`${classes.desc} font-18`}>
                                {t('home.about_company.card_02.text')}
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.card} ${classes.card_03}`}>
                        <div className={classes.content}>
                            <img className={classes.check} src={check} alt='' />
                            <p className={`${classes.desc} font-18`}>
                                {t('home.about_company.card_03.text')}
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.card} ${classes.card_04}`}></div>
                    <div className={`${classes.card} ${classes.card_05} font-28`}>
                        <Marquee autoFill speed={120}>
                            Alenso
                            <img className={classes.star} src={star} alt='' />
                        </Marquee>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutCompany;
