import React from "react";
import classes from './Features.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Heading from "../../GlobalComponents/Heading/Heading";
import image_01 from '../../../assets/img/HomePage/Features/image_01.webp';
import image_02 from '../../../assets/img/HomePage/Features/image_02.webp';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        subtitle: 'home.features.card_01.subtitle',
        text: 'home.features.card_01.text',
        image: image_01,
        class: classes.card_01,
    },
    {
        subtitle: 'home.features.card_02.subtitle',
        text: 'home.features.card_02.text',
        image: image_02,
        class: classes.card_02,
    },
    {
        subtitle: 'home.features.card_03.subtitle',
        text: 'home.features.card_03.text',
        class: classes.card_03,
    },
    {
        subtitle: 'home.features.card_04.subtitle',
        button: true,
        class: classes.card_04,
    },
];

const Features = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Heading center>
                    {t('home.features.heading')}
                </Heading>
                <h2 className={`${classes.title} font-60`}>
                    <Trans>
                        {t('home.features.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={`${classes.card} ${card.class}`}>
                            <div className={classes.content}>
                                <h4 className={`${classes.subtitle} font-32`}>
                                    {t(card.subtitle)}
                                </h4>
                                {card.text &&
                                    <p className={`${classes.text} font-18`}>
                                        {t(card.text)}
                                    </p>
                                }
                                {card.button &&
                                    <Button wfull dark />
                                }
                            </div>
                            {card.image &&
                                <img className={classes.image} src={card.image} alt='' />
                            }
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Features;