import React, { useState, useEffect } from "react";
import classes from './Questions.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/questions.webp';
import { validateEmail, validateString } from "./validation";
import axios from 'axios';
import Button from "../../GlobalComponents/Button/Button";
import successIcon from '../../../assets/img/HomePage/Form/success.svg';
import errorIcon from '../../../assets/img/HomePage/Form/error.svg';
import processIcon from '../../../assets/img/HomePage/Form/process.svg';

const apiUrl = process.env.REACT_APP_FORM_API;

const Questions = () => {
    const { t } = useTranslation();

    const [success, setSuccess] = useState(false);
    const [process, setProcess] = useState(false);
    const [error, setError] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const setDataDefaultState = () => {
        setName('');
        setEmail('');
        setNameValid(true);
        setEmailValid(true);
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];

        validArr.push(validateString(name, setNameValid));
        validArr.push(validateEmail(email, setEmailValid));

        validArr.forEach((item) => {
            if (!item) {
                valid = false;
            }
        });

        return valid;
    };

    const sendMessage = async () => {

        setProcess(true);

        const messageData = {
            name,
            email,
        };

        setDataDefaultState();

        try {
            const data = await axios.post(apiUrl, messageData);

            setProcess(false);

            if (data.status === 200) {
                setSuccess(true);
            } else {
                setError(true);
            }

        } catch (error) {
            setProcess(false);
            setError(true);
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        !nameValid && validateString(name, setNameValid);
        // eslint-disable-next-line
    }, [name]);
    useEffect(() => {
        !emailValid && validateEmail(email, setEmailValid);
        // eslint-disable-next-line
    }, [email]);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-60`}>
                        {t('home.questions.title')}
                    </h2>
                    <p className={`${classes.text} font-20`}>
                        {t('home.questions.text')}
                    </p>
                    <div className={classes.formWrap}>
                        <div className={`${classes.status} ${classes.statusError} ${error ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={errorIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                <Trans>
                                    {t('home.questions.form.error')}
                                </Trans>
                            </p>
                        </div>
                        <div className={`${classes.status} ${classes.statusSuccess} ${success ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={successIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                <Trans>
                                    {t('home.questions.form.success')}
                                </Trans>
                            </p>
                        </div>
                        <div className={`${classes.status} ${classes.statusProcess} ${process ? classes.active : ''}`}>
                            <img className={classes.statusIcon} src={processIcon} alt='' />
                            <p className={`${classes.statusText} font-20`}>
                                {t('home.questions.form.process')}
                            </p>
                        </div>
                        <form className={`
                            ${classes.form}
                            ${(process || success || error) ? classes.blur : ''}
                        `}>
                            <div className={classes.inputs}>
                                <input
                                    type="text"
                                    placeholder={t('home.questions.form.name')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className={`${classes.input} ${!nameValid ? classes.error : ''}`}
                                />
                                <input
                                    type="email"
                                    placeholder={t('home.questions.form.email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={`${classes.input} ${!emailValid ? classes.error : ''}`}
                                />
                            </div>
                            <div className={classes.btn}>
                                <Button wfull event={submit} />
                            </div>
                            <p className={classes.agree}>
                                {t('home.questions.form.agree')}
                            </p>
                        </form>
                    </div>
                </div>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Questions;
