import React from "react";
import classes from './Process.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Heading from "../../GlobalComponents/Heading/Heading";
import { useParams } from "react-router-dom";

const cards = [
    {
        subtitle: 'home.process.card_01.subtitle',
        text: 'home.process.card_01.text',
    },
    {
        subtitle: 'home.process.card_02.subtitle',
        text: 'home.process.card_02.text',
    },
    {
        subtitle: 'home.process.card_03.subtitle',
        text: 'home.process.card_03.text',
    },
];

const Process = () => {
    const { t } = useTranslation();
    const { lang } = useParams();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Heading center>
                    {t('home.process.heading')}
                </Heading>
                <h2 className={`${classes.title} font-60`}>
                    <Trans>
                        {t('home.process.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <div className={`${classes.num} font-60`}>
                                {index + 1}
                            </div>
                            <h4 className={`${classes.subtitle} font-24`}>
                                <Trans components={{a: <a href={`${process.env.REACT_APP_CABINET_URL}/signup?lang=${lang}`}></a>}}>
                                    {t(card.subtitle)}
                                </Trans> 
                            </h4>
                            <p className={`${classes.text} font-18`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Process;
